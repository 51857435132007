import React from 'react';
import { Skeleton } from '@mui/material';
import config from '@configFile';
import { EventStrObj } from '@types';

export const LoadingSkeleton = () => {
  return (
    <Skeleton
      variant="text"
      sx={{
        width: {
          xs: 200,
          sm: 520
        },
        height: {
          xs: 72.5,
          sm: 56
        }
      }}
    />
  );
};

export const getMarginStyle = (isMobile: boolean) => {
  return {
    marginRight: isMobile ? '0.2rem' : '0.5rem',
    verticalAlign: 'middle',
    lineHeight: isMobile ? 'initial' : '46px',
    display: 'flex',
    justifyContent: 'center' as any,
    flexDirection: 'column'
  };
};

export const getEventDayEvent = (currentEvent: EventStrObj) => {
  if (currentEvent?.showCountdown && currentEvent?.eventStartDate) {
    return {
      start: currentEvent.eventStartDate,
      title: `${currentEvent.label} Sale`,
      link: config.PRIME_DAY_LINK
    };
  }
  return null;
};
